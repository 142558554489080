:root {
	--mon: 'Montserrat', sans-serif;
	--xb: 800;
	--m: 500;
	--b: 600;
	--dark: #08090a;
	--light: #f6f6f6;
	--yellow: #ffbd00;
	--blue: #0085ff;
	--grey: #222222;
	--greyL: #e0e0e0;
	--blueL: #128aff;
	--yellowL: #f2b300;
	--green: #00e175;
	--red: #ff2525;
	--doco: #22006a;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: var(--mon);
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
	background-color: var(--dark);
	color: var(--light);
	padding: 48px 32px;
}
a {
	color: inherit;
	text-decoration: none;
}

.top {
	top: 0;
	left: 0;
	width: 100%;
	height: 35vh;
	padding: 40px 32px;
	background-color: var(--dark);
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 99;
}

.greeting {
	font-size: 28px;
	font-weight: var(--m);
	padding-bottom: 10px;
}

.msg {
	font-size: 18px;
}

.buttons {
	padding: 15px 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.btn {
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 25px;
	margin-right: 8px;
	border-radius: 4px;
	text-decoration: none;
	font-size: 14px;
	text-align: center;
	font-weight: 500;
}

.btn.p {
	color: var(--light);
	background-color: var(--blue);
}

.btn.s {
	padding: 4px 15px;
	color: var(--light);
	background-color: var(--dark);
	border: 1.8px solid var(--light);
}

.socials {
	padding: 20px 0;
	border-radius: 10px 0px 0px 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 60px;
	right: 0;
	top: 36vh;
	background-color: var(--grey);
}

.icon {
	padding: 10px;
}

.icon img {
	width: 28px;
}

.scroll {
	position: absolute;
	width: 70%;
	top: 36vh;
	padding-right: 12px;
	padding-bottom: 50px;
}

.section {
	margin-bottom: 40px;
}

.section .head {
	font-size: 24px;
	margin-bottom: 10px;
}

.section .link {
	width: 100%;
	min-height: 52px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--grey);
	border-radius: 8px;
	padding: 10px 20px;
	margin: 16px 0px;
	text-decoration: none;
	transition: all 200ms;
}

.section .link.b {
	min-height: 102px;
	align-items: flex-end;
	padding: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: scroll;
	position: relative;
	font-weight: 600;
}

.section .link:hover .txt {
	font-size: 22px;
}

.section .link:hover {
	transform: translateX(8px);
	transform-origin: left;
	background: #0085ff;
}

.section .link.b:hover:before {
	background: rgba(0, 0, 0, 0.6);
}

.section .link .txt {
	font-size: 18px;
	color: var(--light);
	z-index: 1;
	transition: all 200ms;
	font-weight: 600;
}

.link.b::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.705);
	transition: all 200ms;
}

.cam {
	background: url(https://images.unsplash.com/photo-1589708447452-a241ce823e0d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80);
}

.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 40px;
	background-color: var(--dark);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--yellow);
	z-index: 99;
}

.footer a {
	color: var(--yellow);
	text-decoration: none;
	transition: all 200ms;
}

.footer a:hover {
	color: var(--blue);
}

.spacer {
	height: 2vh;
	width: 100%;
}

.section .events {
	display: flex;
	margin-top: 8px;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	width: 100%;
}

.section .events::-webkit-scrollbar {
	display: none;
}

.section .event .card {
	display: flex;
	flex-direction: column;
	background-color: var(--grey);
	padding: 12px;
	border-radius: 8px;
	max-width: 300px;
	min-width: 190px;
	margin-right: 8px;
	flex: 1;
}
.section .event .card .img img {
	width: 100%;
	border-radius: 4px;
}
.section .event .card .img {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.section .event .card .text {
	padding: 10px 4px;
}

.section .event .card .text .name {
	font-weight: 600;
	margin-bottom: 10px;
}

.section .event .card .text .info {
	display: flex;
	flex-direction: column;
}

.section .event .card .info .date {
	padding: 4px 0;
	font-size: 18px;
	font-weight: 600;
}

.section .event .card .info .time {
	font-weight: 700;
	color: var(--yellow);
}

.section .event .btnHolder {
	padding: 2px;
	width: 100%;
	margin-top: 5px;
}
.section .event .btnHolder .btn {
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px;
	width: 100%;
	border-radius: 4px;
	text-decoration: none;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}

.section .videos {
	display: flex;
	margin-top: 8px;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	width: 100%;
}

.section .videos::-webkit-scrollbar {
	display: none;
}

.section .video {
	display: flex;
}

.section .video .card {
	display: flex;
	flex-direction: column;
	background-color: var(--grey);
	padding: 12px;
	border-radius: 8px;
	max-width: 300px;
	min-width: 200px;
	margin-right: 8px;
	flex: 1;
}
.section .video .card .img img {
	width: 100%;
	border-radius: 4px;
}
.section .video .card .img {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.section .video .card .text {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.section .video .card .text .name {
	font-size: 18px;
	font-weight: 500;
	flex: 1;
}

.section .video .card .text .name.c {
	font-size: 20px;
	font-weight: 700;
	text-align: center;
}

.section .video .card .text .date {
	color: var(--blue);
	font-weight: 700;
	font-size: 14px;
}

@media screen and (min-width: 640px) {
	body {
		padding: 48px 100px;
	}
	.top {
		padding: 48px 100px;
	}
	.greeting {
		font-size: 32px;
	}
	.msg {
		font-size: 22px;
	}
	.section .head {
		font-size: 30px;
		font-weight: 500;
	}
	.section .link .txt {
		font-size: 22px;
		font-weight: 600;
	}
	.section .link:hover .txt {
		font-size: 26px;
	}
	.btn {
		font-size: 16px;
		font-weight: 500;
	}
	.section .btnHolder .btn {
		font-size: 16px;
		font-weight: 500;
		height: 42px;
	}
	.section .card {
		margin-right: 10px;
	}
}
