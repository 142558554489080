:root {
	--light: #f6f6f6;
	--dark: #222222;
	--doco: #22006a;
	--yellow: #ffbd00;
	--blue: #0085ff;
	--green: #00e175;
	--red: #ff2525;
}

body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--dark);
	color: var(--light);
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #222222;
}

::-webkit-scrollbar-thumb {
	background: #444;
	border-radius: 500px;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}
